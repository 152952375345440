import {db} from '@/firebase/config.js'
import { collection, getDocs, doc, getDoc, updateDoc, arrayUnion, query, orderBy, where  } from 'firebase/firestore'

import store from '@/store';


// created this function to replace the moment dependecies
// moment().valueOf() function was used previously...
// so instead of using it, i've used the vanilla js
const getCurrentDateInMillis = () => {
    const currentDate = new Date();
    const currentDateMillis = currentDate.getTime();
    return currentDateMillis;
  }

// get all claimants list
export const fetchClaimants = async (type = null) => {

    // let querySnapshot = await getDocs(query(collection(db,'claimants'), orderBy('email')))
    let querySnapshot = await getDocs(query(collection(db,'claimants'), orderBy('metadata.submittedOn', 'desc')))

    // switch (type) {
    //     case 'pendings':
    //             // querySnapshot = await getDocs(query(
    //             //     collection(db,'claimants'), 
    //             //     where('achievementBoxInfoStatus', '!=', 'approved'),
    //             //     where('postExamSurveyInfoStatus', '!=', 'approved'),
    //             //     orderBy('email')
    //             // ));

    //             querySnapshot = await getDocs(query(
    //                 collection(db, 'claimants'),
    //                 where('achievementBoxInfoStatus', '!=', 'approved'),
    //                 where('postExamSurveyInfoStatus', '!=', 'approved'),
    //                 orderBy('email')
    //             ));

    //         break;
    //     case 'approved':
    //         querySnapshot = await getDocs(query(collection(db,'claimants'), where('achievementBoxInfoStatus','==','approved'),where('postExamSurveyInfoStatus','==','approved'), orderBy('email')))
    //         break;
    
    //     default:
    //         querySnapshot = await getDocs(query(collection(db,'claimants'), orderBy('email')))
    //         break;
    // }

    let claimants = [];
    querySnapshot.forEach((doc)=>{

        claimants.push({
            id: doc.id,
            data: doc.data()
        })

    })


    return claimants;
}

// get all schools list
// return can be object or in array
export const fetchSchools = async (isInObject = false) => {
    const querySnapshot = await getDocs(collection(db,'schools'))
    let objSchools = {};
    let arrSchools = [];

    querySnapshot.forEach((doc)=>{

        if (isInObject) {
            objSchools[doc.id] = doc.data()
        }else{
            arrSchools.push({
                id: doc.id,
                data: doc.data()
            })
        }
    })

    return isInObject ? objSchools : arrSchools;

}

// get user or claimant information in claimants collection
export const getClaimantsInfo = async (userId) => {
    const docSnap = await getDoc(doc(db, 'claimants', userId));
    return docSnap.exists() ? docSnap.data() : null;
}

export const fetchUserScorecard = async (userId) => {

    const res = [];

    const q = query(
        collection(db, 'exam_results'),
        where('userId','==', userId)
    );
    
    // Execute the query
    await getDocs(q)
        .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // Access document data here
            res.push({
                id: doc.id,
                data: doc.data()
            })
        });
        })
        .catch((error) => {
        console.log('Error getting documents: ', error);
        });

        return res;

}

// get user informatio
export const getUserInfo = async (userId) => {
    const docSnap = await getDoc(doc(db, 'users', userId));
    return docSnap.exists() ? docSnap.data() : null;
}

// get post exam survey information
export const getPostExamSurvey = async (docId) => {
    const docSnap = await getDoc(doc(db, 'post_exam_survey', docId));
    return docSnap.exists() ? docSnap.data() : null;
}

// get achievement box information
export const getAchievementBox = async (docId) => {
    const docSnap = await getDoc(doc(db, 'achievement_box', docId));
    return docSnap.exists() ? docSnap.data() : null;
}


export const rejectAchievementBox = async (docId, rejectReason) => {

    // get the rejected count first
    const docSnap = await getDoc(doc(db, 'achievement_box', docId));
    const rejectCount = docSnap.exists() && docSnap.data().metadata.rejected ? docSnap.data().metadata.rejected : 0;
    const curDate = getCurrentDateInMillis().toString();

    return await Promise.all([
        updateDoc(doc(db,'achievement_box',docId), {
            status: 'rejected',
            'metadata.updatedOn' : curDate,
            'metadata.rejected' : rejectCount + 1,
            reasonsForRejection: arrayUnion(rejectReason)
        }),
        updateDoc(doc(db,'claimants',docId), {
            achievementBoxInfoStatus: 'rejected',
            'metadata.updatedOn' : curDate,
        })
    ])

}


export const approveAchievementBox = async (docId) => {

    const curDate = getCurrentDateInMillis().toString();
    // get email of the admin user
    const curAuthUser = store.state.user.otherInfo.email;

    return await Promise.all([
        updateDoc(doc(db,'achievement_box', docId), {
            status: 'approved',
            'metadata.updatedOn' : curDate,
            'metadata.approvedOn' : curDate,
            'metadata.approvedBy' : curAuthUser
        }),
        updateDoc(doc(db,'claimants', docId), {
            achievementBoxInfoStatus: 'approved',
            'metadata.updatedOn' : curDate,
        })
    ])

}


export const rejectPostExamSurvey = async (docId, rejectReason) => {

    // get the rejected count first
    const docSnap = await getDoc(doc(db, 'post_exam_survey', docId));
    const rejectCount = docSnap.exists() && docSnap.data().metadata.rejected ? docSnap.data().metadata.rejected : 0;
    const curDate = getCurrentDateInMillis().toString();

    return await Promise.all([
        updateDoc(doc(db,'post_exam_survey',docId), {
            status: 'rejected',
            'metadata.updatedOn' : curDate,
            'metadata.rejected' : rejectCount + 1,
            reasonsForRejection: arrayUnion(rejectReason)
        }),
        updateDoc(doc(db,'claimants',docId), {
            postExamSurveyInfoStatus: 'rejected',
            'metadata.updatedOn' : curDate,
        })
    ])

}


export const approvePostExamSurvey = async (docId) => {

    const curDate = getCurrentDateInMillis().toString();
    // get email of the admin user
    const curAuthUser = store.state.user.otherInfo.email;

    return await Promise.all([
        updateDoc(doc(db,'post_exam_survey', docId), {
            status: 'approved',
            'metadata.updatedOn' : curDate,
            'metadata.approvedOn' : curDate,
            'metadata.approvedBy' : curAuthUser
        }),
        updateDoc(doc(db,'claimants', docId), {
            postExamSurveyInfoStatus: 'approved',
            'metadata.updatedOn' : curDate,
        })
    ])

}


export const updateShippingInfo = async (docId, shippingInfo) => {

    const curDate = getCurrentDateInMillis().toString();
    const currentDate = new Date(shippingInfo.shippedOn);
    const shippedOn = currentDate.getTime()

    return await Promise.all([
        updateDoc(doc(db,'achievement_box', docId), {
            status: 'shipped',
            courierName: shippingInfo.courierName,
            trackingNum: shippingInfo.trackingNum,
            trackingUrl: shippingInfo.trackingUrl,
            'metadata.shippedOn' : shippedOn.toString(),
            'metadata.shippedBy' : shippingInfo.shippedBy,
            'metadata.updatedOn' : curDate,
            isEmailSent: false
        }),
        updateDoc(doc(db,'claimants', docId), {
            achievementBoxInfoStatus: 'shipped',
            'metadata.updatedOn' : curDate,
        })
    ])

}

export const sentEmailUpdateInfo = async (docId) => {

    return await Promise.all([
        updateDoc(doc(db,'achievement_box', docId), {
            isEmailSent: true
        }),
    ])

}